@font-face {
    font-family: Gruppo;
    src: url('./Gruppo-Regular.ttf')
}

html {
    font-family: Gruppo;
    font-size: 1.5em;
}

a {
    text-decoration: none;
    color: black;
}

body {
    overflow: hidden;
}

#p5 {
    visibility: hidden;
    height: 0%;
    width: 0%;
    overflow: hidden;
}

#back_button {
    margin-right: 15px;
}

#root {
    width: 100%;
    height: 100%;
    margin: 0;
}

.hover:hover {
    cursor: pointer;
}

.smallArrow{
    width: 15px;
}

.Home {
    width: 100%;
    height: 100%;
}

#HomeBody {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#HomeLogo {
    font-size: 1.5em;
    --animate-duration: 5s;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#HomeAbout {
    --animate-duration: 10s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white
}

#leftAlign {
    text-align: left;
    width: 100%;
    height: 45%;
    margin-top: 10px;
}

#rightAlign {
    /* margin-top: 200px; */
    text-align: right;
    width: 100%;
    margin-bottom: 10px;
}

.Header {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#HeaderRight {
    display: flex;
    justify-content: space-between;
}

.MenuItem {
    margin-right: 15px;
}

.Work {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Loading {
    width: 25%;
}

.ProgressBar {
    height: 5px;
    width: 100%;
}

#FooterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 10%;
    font-size: 1em;
}

#canvas {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

canvas {
    width: 100%;
    height: 100%;
}

#scene {
    width: 100%;
    height: 100%;
}

#css,
#webgl {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 11%;
    /* left: 2.5%; */
}

#css,
#cssScene {
    z-index: 100;
}

#webglScene {}

#cssScene,
#webglScene {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 10%;
    left: 0;
}

#Work {
    overflow: scroll;
}

#workwork {
    z-index: -100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery {
    width: 500px;
    height: 500px;
    z-index: -100;
    /* overflow: hidden; */
}

#GalleryArray {
    /* visibility: hidden; */
    /* z-index: -100; */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 200px;
}

#galleries {
    z-index: -100;
    visibility: hidden;
}

.slideShow {
    --animate-duration: 4s;
    /* background-color: white; */
    width: 1000px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.each-slide>div {
    width: 100%;
    height: 100%;
}

.react-slideshow-container {
    width: 100%;
    height: 100%;
}

.each-slide {
    overflow: visible;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#bg {
    transform: scale(0.9);
    /* background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
}

.each-slide span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

#prevArrow,
#nextArrow {
    font-size: large;
    color: white;
    position: absolute;
    right: 0;
    /* --spacing: 25px; */
    --spacing: 45%;
}

#prevArrow {
    top: var(--spacing);
    left: 0;
}

#nextArrow {
    bottom: var(--spacing);
    left: 0;
}


.writeup {
    width: 770px;
    margin-left: 5px;
    height: 500px;
    overflow-y: scroll;
    visibility: hidden;
    /* background-color: white; */
    /* font-size: 0.8em; */
}

#about {
    width: 200px;
    height: 200px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dg {
    position: absolute;
    z-index: 2000;
    top: 50%;
    left: 50%;
}

#writeups {
    position: absolute;
    top: -1000;
    z-index: 0;
    height: 10px;
    overflow: hidden;
}


/*
============================================================================================= 
 Mobile
============================================================================================= 
 */
@media only screen and (max-width: 800px) {
    #rightAlign {
        /* margin-top: 200px; */
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
    }

    #HomeAbout {
        --animate-duration: 10s;
    }

    .react-slideshow-container{
        height: 100%;
        width: 100%;
        /* overflow: hidden; */
    }

    .slideShow {
        --animate-duration: 4s;
        width: 1000px;
        height: 1000px;
        /* width: 1000px;
        height: ; */
        /* display: flex;
        align-items: center;
        justify-content: center; */
    }

    .writeup {
        width: 900px;
        margin-left: 5px;
        height: 1000px;
        top: 0;
        overscroll-behavior-y: contain;
        /* overflow-y: hidden; */
        overflow-y: scroll;
        visibility: hidden;
        /* background-color: red; */
        font-size: 2em;
        color: white;
    }

    .each-slide{
        width: 1000px;
        height: 1500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

     #bg
     {
        width: 1000px;
        height: auto;
    } 

    .arrow{
        visibility: hidden;
    }

    #prevArrow,
    #nextArrow {
        z-index: 20000;
        font-size: 1.5em;
        /* position: absolute; */
        --spacing: -1000000px;
        /* --spacing: 1000px; */
        --wSpacing: 400px;
    }

    #nextArrow {
        top: var(--spacing);
        left: 870px;
    }
    #prevArrow {
        top: var(--spacing);
        left: 55px;
    }
}